<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
      v-if="handleShowMenu(item.header, profile.role)"
    />
  </ul>
</template>

<script>
import { mapGetters} from 'vuex'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import VerticalNavMenuHeader from '../vertical-nav-menu-header/VerticalNavMenuHeader.vue'
import MenuReportPermission from '@/common/permission/menu/report'

export default {
  data () {
    return {
      isLoaded: true
    }
  },
  mounted () {
  },
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user',
    })
  },
  methods: {
    handleShowMenu(header, role) {
      if(this.handleHeader.includes(header) && !this.menuReportPermission.includes(role)) {
        return false
      }
      return true
    }
  },
  data () {
    return {
      menuReportPermission: MenuReportPermission,
      handleHeader: [
      'Report',
      'Ticket Detail Report',
      'Ticket Summary By Agent', 
      'Ticket Summary By Channel',
      'Ticket Summary By Case Type',
      'Detail Report',
      'Categories Report'
    ]
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>
