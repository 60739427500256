<template>
<li>
  <b-link
      class="nav-link"
      :to="{ name: item.route }"
    >
      <span>{{ item.title }}</span>
    </b-link>
</li>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  render(h) {
    const span = h('span', {}, t(this.item.header))
    const icon = h('feather-icon', { props: { icon: 'MoreHorizontalIcon', size: '18' } })
    if (canViewVerticalNavMenuHeader(this.item)) {
      return h('li', { class: 'navigation-header text-truncate' }, [span, icon])
    }
    return h()
  },
}
</script>
