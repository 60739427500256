<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <!-- Version 1.0.4 (GMWNNK7M41) -->
      <!-- <b-link
        class="ml-25"
        href="https://1.envato.market/pixinvent_portfolio"
        target="_blank"
      >Pixinvent</b-link> -->
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">Ticket Management System v{{ versionApp }}
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import version from '../../../../version.json'
export default {
  components: {
    BLink,
  },
  computed: {
    versionApp () {
      return version.version
    },
  }
}
</script>
